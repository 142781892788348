/**
 * global constants
 */
window.CurrentUser = {
    userId: '',
    email: '',
    ethAddress: '',
    token: ''
};

window.constants = {
    LANGUAGE_EN: "EN",
    LANGUAGE_CN: "CN",
    LANGUAGE_KEY: "languageKey",
    CURRENT_USER: "CurrentWebUser",
    PUBLISH_KEY: "pk_live_51HXbkqEGGxp6oXEU8cJz4V3H7WRdt9UfaEkid8Fm1RyauiyluRN6OrN4MnE1iBbdtIUXjmscU42NUgwwYFVmdXCV00ajTlUNYd",
    PADDLE_VENDOR_ID: 130220,
    COIN_PAYMENT_MERCHANT: "fa9dd2fa1e6f8e5101b75c8b192fd576",
    COIN_PAYMENT_IPN_URL:"https://api.callpanda.net/open/payment/callback-coinpayment-order",
    SUPPORT_EMAIL: "support@squirrelvpn.com",
    INVITATION_LINK: "https://squirrelvpn.com/v/"
};

window.downloads = {
    Android: 'https://squirrelvpn.com/releases/SquirrelVPN-Android-1.7.3-188-release.apk',
    GooglePlay: 'https://play.google.com/store/apps/details?id=com.squirrelvpn',
    TestFlight: 'https://testflight.apple.com/join/RA5AyZzM',
    AppStore: 'https://apps.apple.com/us/app/squirrelvpn/id1480069733',
    MicrosoftStore: 'https://www.microsoft.com/store/apps/9nsmq6f5g1z5',
    Windows: 'https://squirrelvpn.com/releases/SquirrelVPN-Win-1.8.0-24-x64-release.msi',
    MacOS: 'https://squirrelvpn.com/releases/SquirrelVPN-Mac-1.8.0-24-x64-release.pkg',
    Linux: 'https://squirrelvpn.com/releases/SquirrelVPN-linux64-1.8.0-24-release.deb',
    GooglePlayTV: 'https://play.google.com/store/apps/details?id=com.squirrelvpntv',
    AndroidTVapk: 'https://squirrelvpn.com/releases/SquirrelVPN-Android-TV-1.7.3-188-release.apk'
};

window.products = {
    month: {
        key: "month",
        id: 10001,
        days: 30,
        month: 1,
        monthText: "1 Month",
        monthTextCN: "购买1个月",
        price: 7.99,
        amount: 7.99,
        paddleProductId: 652415
    },
    half_year: {
        key: "half_year",
        id: 10002,
        days: 180,
        month: 6,
        monthText: "6 Months",
        monthTextCN: "购买6个月",
        price: 6.99,
        amount: 41.99,
        paddleProductId: 654150
    },
    year: {
        key: "year",
        id: 10003,
        days: 365,
        month: 12,
        monthText: "12 Months",
        monthTextCN: "购买12个月",
        price: 5.83,
        amount: 69.99,
        paddleProductId: 654151
    }
};
